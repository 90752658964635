
import { Component, Vue } from 'nuxt-property-decorator'
import vSelect from 'vue-select'
import DocValueAbstract from '@pp-frontend/shared-packages/classes/doc-value/DocValueAbstract'
import DocIp from '@pp-frontend/shared-packages/classes/doc-value/fssp/DocIp'
import DocPerson from '@pp-frontend/shared-packages/classes/doc-value/fssp/DocPerson'
import SearchFormPerson from '~/components/index/SearchFormPerson.vue'
import { LegalStatusEnum } from '~/components/enums/LegalStatus.enum'

interface FormSectionsInterface {
  id?: number;
  name: string;
  isOpen: boolean;
  legalStatus: LegalStatusEnum;
  formData: DocValueAbstract
}

interface Qwe {
  legalStatus: LegalStatusEnum;
  formData?: DocValueAbstract
}

export enum LegalNameStatusEnum {
  person = 'Физическое лицо',
  document = 'Документы',
  legal = 'Номер ИП',
  ip = 'Номер ИП',
  id = 'Номер ИД'
}

@Component({
  components: {
    SearchFormIp: () => import('~/components/index/SearchFormIp.vue'),
    SearchFormPerson,
    vSelect
  }
})
export default class extends Vue {
  sectionActive: Qwe = {
    legalStatus: LegalStatusEnum.Person,
    formData: new DocPerson()
  }

  LegalNameStatusEnum = LegalNameStatusEnum

  formSectionsArr: FormSectionsInterface[] = [
    {
      id: 0,
      name: 'Физическое лицо',
      isOpen: true,
      legalStatus: LegalStatusEnum.Person,
      formData: new DocPerson()
    },
    // {
    //   id: 1,
    //   name: 'Документы',
    //   isOpen: false,
    //   legalStatus: LegalStatusEnum.Document,
    //   formData: new DocGis()
    // },
    // {
    //   id: 2,
    //   name: 'Юридическое лицо',
    //   isOpen: false,
    //   legalStatus: LegalStatusEnum.Legal,
    //   formData: new DocLegal()
    // },
    {
      id: 3,
      name: 'Номер ИП',
      isOpen: false,
      legalStatus: LegalStatusEnum.Ip,
      formData: new DocIp()
    }
    // {
    //   id: 4,
    //   name: 'Номер ИД',
    //   isOpen: false,
    //   legalStatus: LegalStatusEnum.Id,
    //   formData: new DocId()
    // }
  ]

  togglesSections (item: FormSectionsInterface, index: number) {
    if (item === null || item.isOpen) {
      return
    }

    this.sectionActive = {
      legalStatus: item.legalStatus,
      formData: item.formData
    }

    this.formSectionsArr.forEach((elem, elemIndex) => {
      if (index === +elemIndex) {
        elem.isOpen = !(item.isOpen)
      } else {
        elem.isOpen = false
      }
    })
  }
}
