
import { Component, Vue } from 'nuxt-property-decorator'
import LazyHydrate from 'vue-lazy-hydration'
import vSelect from 'vue-select'
import Articles from '~/components/index/Articles.vue'
import Departments from '~/components/index/Departments.vue'
import Features from '~/components/Features.vue'
import News from '~/components/index/News.vue'
import { LegalStatusEnum } from '~/components/enums/LegalStatus.enum'
import Banner from '~/components/banner/Banner.vue'
import Faq from '~/components/index/Faq.vue'
import SearchForm from '~/components/index/SearchForm.vue'
import FnsIndexWidgets from '~/components/index/FnsIndexWidgets.vue'

@Component({
  components: {
    FnsIndexWidgets,
    SearchForm,
    Faq,
    LazyHydrate,
    Articles,
    Departments,
    Features,
    News,
    Banner,
    vSelect,
    BookMarkPopup: () => import('@pp-frontend/popups/component/BookMarkPopup.vue')
  }
})
export default class extends Vue {
  PopupMobileApp: any = () => import('~/components/popups/PopupMobileApp.vue');
  legalStatusEnum = LegalStatusEnum
  deferredPrompt: any

  head () {
    return {
      title: 'Проверка задолженности у судебных приставов по фамилии онлайн\n',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Проверка долгов ФССП и оплата онлайн. Узнать долги у судебных приставов можно по фамилии бесплатно и оплатить любой картой.'
        }
      ],
      link: [
        { rel: 'canonical', href: 'https://oplata-fssp.ru' }
      ]
    }
  }

  mounted () {
    if (this.$route.query.email) {
      this.$axios.patch(process.env.API_ENDPOINT + 'user', { email: this.$route.query.email })
    }

    window.addEventListener('load', () => {
      this.$axios.get(process.env.API_ENDPOINT + 'user/event', {
        params: Object.assign(this.$ga.getStorage(), {
          event_action: 'main',
          accrual_type_id: 'fssp'
        })
      })
    }, { once: true })
    window.document.addEventListener('mouseleave', this.onMouseLeave)
  }

  onMouseLeave (e) {
    // @ts-ignore
    if (this.$webPush.allowed()) {
      if (e.clientY <= 0) {
        this.$eventBus.$emit('WebPushPermit')
        window.document.removeEventListener('mouseleave', this.onMouseLeave)
      }
    }
  }
}
